<template>
  <ion-page>
    <ion-content :fullscreen="true" class="device-check-content">
      <div class="common-body-720-90 pb40">
        <!-- top nav -->
        <div
          class="flexCenter dflex device-check-top-nav"
          v-show="stepCount > 1 && stepCount < 6"
        >
          <div
            class="device-check-top-nav-icon flexCenter dflex"
            :class="{ active: stepCount >= 2 }"
          >
            <ion-icon :icon="videocamOutline"></ion-icon>
          </div>
          <div
            class="device-check-top-nav-bar"
            :class="{ active: stepCount >= 3 }"
          ></div>
          <div
            class="device-check-top-nav-icon flexCenter dflex"
            :class="{ active: stepCount >= 3 }"
          >
            <ion-icon :icon="volumeMediumOutline"></ion-icon>
          </div>
          <div
            class="device-check-top-nav-bar"
            :class="{ active: stepCount >= 4 }"
          ></div>
          <div
            class="device-check-top-nav-icon flexCenter dflex"
            :class="{ active: stepCount >= 4 }"
          >
            <ion-icon :icon="micOutline"></ion-icon>
          </div>
          <div
            class="device-check-top-nav-bar"
            :class="{ active: stepCount >= 5 }"
          ></div>
          <div
            class="device-check-top-nav-icon flexCenter dflex"
            :class="{ active: stepCount >= 5 }"
          >
            <ion-icon :icon="globeOutline"></ion-icon>
          </div>
        </div>
        <!-- step.1 -->
        <div v-show="stepCount === 1">
          <div class="device-check-stp1-title">設備テスト</div>
          <div class="device-check-stp1-sub-title">
            カメラ・マイクの権限を許可してください。
          </div>
          <div class="device-check-stp1-mid-body">
            <div class="device-check-stp1-icon-body clearfix">
              <div class="device-check-stp1-icon-item">
                <ion-icon :icon="videocamOutline"></ion-icon>
                <div v-if="step1CheckFlag" class="device-check-checked-icon">
                  <ion-icon
                    :icon="checkmarkOutline"
                    class="device-check-checked-ok"
                    v-if="hasCameraConnect"
                  ></ion-icon>
                  <ion-icon
                    :icon="closeOutline"
                    class="device-check-checked-error"
                    v-else
                  ></ion-icon>
                </div>
              </div>
              <div class="device-check-stp1-icon-item">
                <ion-icon :icon="volumeMediumOutline"></ion-icon>
                <div v-if="step1CheckFlag" class="device-check-checked-icon">
                  <ion-icon
                    :icon="checkmarkOutline"
                    class="device-check-checked-ok"
                    v-if="hasVoiceConnect"
                  ></ion-icon>
                  <ion-icon
                    :icon="closeOutline"
                    class="device-check-checked-error"
                    v-else
                  ></ion-icon>
                </div>
              </div>
              <div class="device-check-stp1-icon-item">
                <ion-icon :icon="micOutline"></ion-icon>
                <div v-if="step1CheckFlag" class="device-check-checked-icon">
                  <ion-icon
                    :icon="checkmarkOutline"
                    class="device-check-checked-ok"
                    v-if="hasMicConnect"
                  ></ion-icon>
                  <ion-icon
                    :icon="closeOutline"
                    class="device-check-checked-error"
                    v-else
                  ></ion-icon>
                </div>
              </div>
              <div class="device-check-stp1-icon-item">
                <ion-icon :icon="globeOutline"></ion-icon>
                <div v-if="step1CheckFlag" class="device-check-checked-icon">
                  <ion-icon
                    :icon="checkmarkOutline"
                    class="device-check-checked-ok"
                    v-if="hasNetworkConnect"
                  ></ion-icon>
                  <ion-icon
                    :icon="closeOutline"
                    class="device-check-checked-error"
                    v-else
                  ></ion-icon>
                </div>
              </div>
            </div>
            <div v-if="!step1CheckFlag" class="device-check-stp1-progress-body">
              <ion-progress-bar
                type="indeterminate"
                class="device-check-stp1-progress"
              ></ion-progress-bar>
            </div>
          </div>
          <div class="device-check-stp1-tips" v-if="!step1CheckFlag">
            設備へ接続中
          </div>
          <div class="device-check-stp1-tips is-ok" v-else>
            <div v-if="step1IsOk">
              設備及びネットの接続が成功しました。テストを開始してください。
            </div>
            <div class="cRed" v-else>
              設備及びネットの接続が失敗しました。もう一度、設備を確認してください。
            </div>
          </div>
          <div class="device-check-stp1-btn-body">
            <button
              type="button"
              class="device-check-stp1-btn"
              :disabled="!(step1IsOk && step1CheckFlag)"
              @click="nextStep"
            >
              テスト開始
            </button>
          </div>
        </div>
        <!-- step.2 -->
        <div v-show="stepCount === 2">
          <div
            id="camera-check-video"
            class="device-check-stp2-video-body"
          ></div>
          <div class="device-check-radio-body">
            <div class="device-check-radio-title" v-if="carameIsOpen">
              カメラ選択
            </div>
            <div class="device-check-radio-title" v-else>カメラ起動中...</div>
            <div v-show="carameIsOpen">
              <div
                v-for="(item, index) in cameraList"
                :key="index"
                class="device-check-radio-item"
              >
                <label class="device-check-radio-label">
                  <input
                    type="radio"
                    :value="item.deviceId"
                    v-model="cameraId"
                    @change="cameraIdChange(item.label)"
                  />
                  {{ item.label }}</label
                >
              </div>
            </div>
          </div>
          <div class="device-check-common-tips">カメラは起動していますか？</div>
        </div>
        <!-- step.3 -->
        <div v-show="stepCount === 3">
          <div class="device-check-stp3-audio-body">
            <audio
              src="https://rococo-1259241735.file.myqcloud.com/prd/mp3/test.mp3"
              controls
              id="deviceCheckAudio"
              controlslist="nodownload"
            ></audio>
          </div>
          <div class="device-check-radio-body" v-if="voiceList.length > 0">
            <div class="device-check-radio-title">スピーカー選択</div>
            <div
              v-for="(item, index) in voiceList"
              :key="index"
              class="device-check-radio-item"
            >
              <label class="device-check-radio-label">
                <input
                  type="radio"
                  :value="item.deviceId"
                  v-model="voiceId"
                  @change="voiceIdChange(item.label)"
                />
                {{ item.label }}</label
              >
            </div>
          </div>
          <div class="device-check-common-tips">
            再生ボタンで音楽が流れます。<br />
            音量を調整してください。
          </div>
        </div>
        <!-- step.4 -->
        <div v-show="stepCount === 4">
          <div class="device-check-stp4-tips">何か話しましょう</div>

          <div class="device-check-stp4-progress-body">
            <ion-progress-bar
              :value="myVolume"
              class="device-check-stp4-progress"
            ></ion-progress-bar>
          </div>
          <div class="device-check-radio-body">
            <div class="device-check-radio-title">マイク選択</div>
            <div
              v-for="(item, index) in micList"
              :key="index"
              class="device-check-radio-item"
            >
              <label class="device-check-radio-label">
                <input
                  type="radio"
                  :value="item.deviceId"
                  v-model="micId"
                  @change="micIdChange(item.label)"
                />
                {{ item.label }}</label
              >
            </div>
          </div>
          <div class="device-check-common-tips">
            声を出した時にピンクのバーは動いていますか？
          </div>
        </div>
        <!-- step.5 -->
        <div v-show="stepCount === 5">
          <div v-show="netWorkStatus === 0">
            <div class="device-check-stp5-title">ネット状況</div>
            <div class="common-authing-body margin-auto devicecheck">
              <div class="common-authing item7">
                <div class="dot dot1"></div>
                <div class="dot dot2"></div>
                <div class="dot dot3"></div>
              </div>
              <div class="tc cWhite">テスト中({{ countDown }}s)</div>
            </div>
            <div class="device-check-stp5-tips">
              テスト中です。約1〜2分かかる場合があります。しばらく経っても自動で画面が切り替わらない場合はネットワーク状態が不安定な可能性があります。<br />
              ネットワーク環境をご確認の上、ブラウザのリロード、または再起動をお試しください。
            </div>
          </div>
          <div v-show="netWorkStatus > 0">
            <!-- <div v-if="netWorkStatus <= 1">
              <div class="device-check-stp5-title">ネット状況</div>
              <div class="device-check-stp5-cir green flexCenter dflex">
                <ion-icon :icon="checkmarkOutline"></ion-icon>
              </div>
              <div class="device-check-stp5-tips">
                現在のネットワークの状態：良い<br />
                このままネットワーク環境を安定させてください。
              </div>
            </div>
            <div v-else-if="netWorkStatus <= 3">
              <div>
                <div class="device-check-stp5-title">ネット状況</div>
                <div class="device-check-stp5-cir orange flexCenter dflex">
                  <ion-icon :icon="alertOutline"></ion-icon>
                </div>
                <div class="device-check-stp5-tips">
                  現在のネットワークの状態：あまり良くない<br />
                  会話が遅延が生じる場合がありますので、ネットワーク環境の良い場所へ移動してください。
                </div>
              </div>
            </div>
            <div v-else-if="netWorkStatus === 7">
              <div>
                <div class="device-check-stp5-title">ネット状況</div>
                <div class="device-check-stp5-cir red flexCenter dflex">
                  <ion-icon :icon="closeOutline"></ion-icon>
                </div>
                <div class="device-check-stp5-tips">
                  現在のネットワークの状態：エラー<br />
                  ネットワークが見つかりません。移動して頂くか推奨環境の確認をお願いします。
                </div>
              </div>
            </div>
            <div v-else>
              <div>
                <div class="device-check-stp5-title">ネット状況</div>
                <div class="device-check-stp5-cir red flexCenter dflex">
                  <ion-icon :icon="closeOutline"></ion-icon>
                </div>
                <div class="device-check-stp5-tips">
                  現在のネットワークの状態：非常に悪い<br />
                  会話が出来ない可能性がありますので、必ずネットワーク環境の良い場所へ移動してください。
                </div>
              </div>
            </div> -->
            <div>
              <div>
                <div class="device-check-stp5-title">通信測定点数</div>
                <div class="flexCenter dflex device-check-stp5-ten">
                  評価：{{ 8 - (netWorkStatus || 7) }}点 / 7点中
                </div>
                <div class="device-check-stp5-tips">
                  ※通信測定基準<br />
                  <div class="flex-row">
                    <div style="flex: 0 0 55px;" class="tr">7：</div>
                    <div style="flex: 1;">良い</div>
                  </div>
                  <div class="flex-row">
                    <div style="flex: 0 0 55px;" class="tr">5～6：</div>
                    <div style="flex: 1;">
                      不安定、画質が落ちる、通信不可の可能性が有り
                    </div>
                  </div>
                  <div class="flex-row">
                    <div style="flex: 0 0 55px;" class="tr">3～4：</div>
                    <div style="flex: 1;">
                      悪い、通信不可の可能性が大いに有る
                    </div>
                  </div>
                  <div class="flex-row">
                    <div style="flex: 0 0 55px;" class="tr">1～2：</div>
                    <div style="flex: 1;">通信不可</div>
                  </div>
                  <br />
                  1～4点の場合は、測定結果が5～7点になるよう場所の移動をお願いいたします。
                </div>
              </div>
            </div>
            <div class="device-check-stp1-btn-body">
              <button
                type="button"
                class="device-check-stp1-btn"
                @click="nextStep"
              >
                テスト結果へ
              </button>
            </div>
          </div>
          <!-- {{ checkList.network.up }} |
          {{ checkList.network.down }} -->
        </div>
        <!-- step.6 -->
        <div v-show="stepCount === 6">
          <div class="device-check-stp6-title">
            テスト結果
          </div>
          <div>
            <table class="device-check-stp6-table">
              <tbody>
                <tr>
                  <td width="50" class="device-check-stp6-table-icon">
                    <ion-icon :icon="videocamOutline"></ion-icon>
                  </td>
                  <td>カメラ</td>
                  <td width="120" class="tr">
                    <div class="cGreen" v-if="checkList.camera">正常</div>
                    <div class="cRed" v-else>異常</div>
                  </td>
                </tr>
                <tr>
                  <td width="50" class="device-check-stp6-table-icon">
                    <ion-icon :icon="volumeMediumOutline"></ion-icon>
                  </td>
                  <td>スピーカー</td>
                  <td width="120" class="tr">
                    <div class="cGreen" v-if="checkList.voice">正常</div>
                    <div class="cRed" v-else>異常</div>
                  </td>
                </tr>
                <tr>
                  <td width="50" class="device-check-stp6-table-icon">
                    <ion-icon :icon="micOutline"></ion-icon>
                  </td>
                  <td>マイク</td>
                  <td width="120" class="tr">
                    <div class="cGreen" v-if="checkList.mic">正常</div>
                    <div class="cRed" v-else>異常</div>
                  </td>
                </tr>
                <tr>
                  <td width="50" class="device-check-stp6-table-icon">
                    <ion-icon :icon="globeOutline"></ion-icon>
                  </td>
                  <td>ネット状況</td>
                  <td width="120" class="tr">
                    <div class="cGreen" v-if="netWorkStatus === 1">良い</div>
                    <div class="cOrangeed" v-else-if="netWorkStatus <= 3">
                      不安定
                    </div>
                    <div class="cRed" v-else-if="netWorkStatus <= 5">
                      悪い
                    </div>
                    <div class="cRed" v-else-if="netWorkStatus <= 7">
                      通信不可
                    </div>
                    <div v-else>未知</div>
                    <!-- <div class="cRed" v-else>悪い</div> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="device-check-common-bottom-btn-body">
            <button
              type="button"
              class="device-check-common-bottom-btn type-left"
              @click="reload"
            >
              やり直す
            </button>
            <button
              type="button"
              class="device-check-common-bottom-btn type-right"
              @click="goRococo"
            >
              完了
            </button>
          </div>
        </div>
        <!-- step.no support -->
        <div v-show="stepCount === 7">
          <div>
            <div>
              <div class="device-check-stp7-title">エラー</div>
              <div class="device-check-stp5-cir red flexCenter dflex">
                <ion-icon :icon="closeOutline"></ion-icon>
              </div>
              <div class="device-check-stp7-res">
                端末またはブラウザはご利用いただけません。<br />
                推奨環境をご確認ください。
              </div>
              <div class="device-check-stp7-tips">
                推奨環境<br />
                <span class="device-check-stp7-circle">○</span>PC<br />
                <span class="device-check-stp7-dot">·</span>Windows10
                (最新バージョンのGoogle Chrome、Microsoft Edge)<br />
                <span class="device-check-stp7-dot">·</span>※Internet
                Explorer未対応<br />
                <span class="device-check-stp7-dot">·</span>macOS11.0以上
                (最新バージョンのGoogle Chrome、Safari)<br />
                <span class="device-check-stp7-circle">○</span
                >スマートフォン・タブレット<br />
                <span class="device-check-stp7-dot">·</span>Android 8
                以上(最新バージョンのGoogle Chrome)<br />
                <span class="device-check-stp7-dot">·</span>iOS
                12.0以上(最新バージョンのSafari、Google Chrome)<br />
                <span class="device-check-stp7-circle">○</span
                >モバイル端末<br />
                <span class="device-check-stp7-dot">·</span
                >iPhone：iPhone7以降（7含む）<br />
                <span class="device-check-stp7-dot">·</span
                >Android：概ね直近3年以内に発売された端末<br />
                <br />
                備考：Huawei端末非対応（使用上制限されている機能があるため）
              </div>
            </div>
          </div>
          <div class="device-check-stp1-btn-body">
            <button
              type="button"
              class="device-check-stp1-btn"
              @click="goRococo"
            >
              戻る
            </button>
          </div>
          <!-- {{ checkList.network.up }} |
          {{ checkList.network.down }} -->
        </div>
        <!-- stepNav -->
        <div
          class="device-check-common-bottom-btn-body"
          v-show="stepCount > 1 && stepCount < 5"
        >
          <button
            type="button"
            class="device-check-common-bottom-btn type-left"
            @click="setError(true)"
          >
            いいえ
          </button>
          <button
            type="button"
            class="device-check-common-bottom-btn type-right"
            @click="setError(false)"
          >
            はい
          </button>
        </div>
      </div>
      <!-- <div>
        <div v-for="(log, index) in logList" :key="index">
          {{ log }}
        </div>
      </div> -->
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent, markRaw } from 'vue'
import { IonContent, IonPage, IonProgressBar, IonIcon } from '@ionic/vue'
import {
  videocamOutline,
  volumeMediumOutline,
  micOutline,
  globeOutline,
  checkmarkOutline,
  closeOutline,
  alertOutline
} from 'ionicons/icons'
import TRTC from 'trtc-js-sdk'
import { authApi } from '../../../api'

export default defineComponent({
  name: 'DeviceCheck',
  data() {
    return {
      videocamOutline,
      volumeMediumOutline,
      micOutline,
      globeOutline,
      checkmarkOutline,
      closeOutline,
      alertOutline,
      // this.$route.query.userid
      userid: 1,

      carameIsOpen: false,

      localStream: null,

      networdCheckError: false,

      step1CheckFlag: false,

      cameraList: [],
      micList: [],
      voiceList: [],

      hasCameraDevice: false,
      hasMicDevice: false,

      hasCameraConnect: false,
      hasVoiceConnect: false,
      hasMicConnect: false,
      hasNetworkConnect: false,

      curTestingPageId: '',

      stepCount: 0,

      cameraTestingResult: {},
      micTestingResult: {},
      voiceTestingResult: {},
      cameraId: null,
      micId: null,
      voiceId: null,
      myVolume: 0,
      myVolumeTimer: null,
      checkList: {
        camera: false,
        voice: false,
        mic: false,
        network: {
          up: 0,
          down: 0
        }
      },
      networkUpLog: [],
      networkDownLog: [],
      logList: [],
      countDown: 0,
      countTimer: null,
      roomId: ''
    }
  },
  components: {
    IonContent,
    IonPage,
    IonProgressBar,
    IonIcon
  },
  computed: {
    netWorkStatus() {
      if (this.networdCheckError) {
        return 7
      } else if (
        this.checkList.network.up === 0 ||
        this.checkList.network.down === 0
      ) {
        return 0
      }
      return Math.max(this.checkList.network.up, this.checkList.network.down)
    },
    step1IsOk() {
      return (
        this.hasCameraConnect &&
        this.hasVoiceConnect &&
        this.hasMicConnect &&
        this.hasNetworkConnect
      )
    }
  },
  mounted() {
    if (this.userid) {
      // this.init()
      TRTC.checkSystemRequirements().then(checkResult => {
        this.logList.push(JSON.stringify(checkResult))
        console.log(checkResult)
        if (!checkResult.result) {
          console.log(
            'checkResult',
            checkResult.result,
            'checkDetail',
            checkResult.detail
          )

          // SDK 不支持当前浏览器，根据用户设备类型建议用户使用 SDK 支持的浏览器
          this.stepCount = 7
        } else {
          const detail = checkResult.detail
          if (
            !detail.isBrowserSupported ||
            !detail.isH264DecodeSupported ||
            !detail.isH264EncodeSupported ||
            !detail.isMediaDevicesSupported ||
            !detail.isVp8DecodeSupported ||
            !detail.isVp8EncodeSupported ||
            !detail.isWebRTCSupported
          ) {
            this.stepCount = 7
          } else {
            this.stepCount = 1
            this.init()
          }
        }
      })
    } else {
      this.goRococo()
    }
  },
  watch: {
    $route() {
      if (this.myVolumeTimer) {
        clearInterval(this.myVolumeTimer)
      }
    }
  },
  methods: {
    goRococo() {
      location.href = 'https://online-talk.jp/history'
    },
    reload() {
      location.reload()
    },
    async voiceIdChange(label) {
      let newVoiceId = this.voiceId
      localStorage.setItem('txy_webRTC_voiceId', newVoiceId)
      this.voiceTestingResult.device = {
        label: label,
        deviceId: newVoiceId,
        kind: 'audiooutput'
      }

      let audioPlayer = document.querySelector('#deviceCheckAudio')
      await audioPlayer.setSinkId(newVoiceId)
      this.logList.push(`voiceIdChange id is ${newVoiceId},label is ${label}`)
    },
    async micIdChange(label) {
      console.log(this.micList)
      let newMicID = this.micId
      localStorage.setItem('txy_webRTC_micId', newMicID)
      this.micTestingResult.device = {
        label: label,
        deviceId: newMicID,
        kind: 'audioinput'
      }
      await this.localStream.switchDevice('audio', newMicID)
      this.logList.push(`micIdChange id is ${newMicID},label is ${label}`)
    },
    async cameraIdChange(label) {
      console.log(this.cameraId)
      let newCameraId = this.cameraId
      localStorage.setItem('txy_webRTC_cameraId', newCameraId)
      this.cameraTestingResult.device = {
        label: label,
        deviceId: newCameraId,
        kind: 'videoinput'
      }
      await this.localStream.switchDevice('video', newCameraId)
      this.logList.push(`cameraIdChange id is ${newCameraId},label is ${label}`)
    },
    setError(isError) {
      switch (this.stepCount) {
        case 2:
          this.checkList.camera = !isError
          //   if (!isError && this.cameraId) {
          //     console.log('储存摄像头ID')
          //     localStorage.setItem('txy_webRTC_cameraId', this.cameraId)
          //   }
          if (this.localStream) {
            this.localStream.close()
            console.log('close localStream')
          }
          this.startVoiceTesting()
          break
        case 3:
          this.checkList.voice = !isError
          //   if (!isError && this.voiceId && this.voiceList.length > 0) {
          //     console.log('储存扬声器ID')
          //     localStorage.setItem('txy_webRTC_voiceId', this.voiceId)
          //   }
          this.startMicTesting()
          break
        case 4:
          this.checkList.mic = !isError
          //   if (!isError && this.micId) {
          //     console.log('储存麦克风ID')
          //     localStorage.setItem('txy_webRTC_micId', this.micId)
          //   }
          if (this.localStream) {
            this.localStream.close()
            console.log('close localStream')
          }
          this.startNetworkTesting()
          break
        default:
          break
      }
      this.nextStep()
    },
    nextStep() {
      switch (this.stepCount) {
        case 1:
          this.startCameraTesting()
          break
        default:
          break
      }
      this.stepCount++
    },
    preStep() {
      this.stepCount--
    },
    async init() {
      await this.getDevicesInfo()
      this.startDeviceConnect()
      setTimeout(() => {
        this.step1CheckFlag = true
      }, 2000)
    },
    async getDevicesInfo() {
      this.cameraList = await TRTC.getCameras()
      this.micList = await TRTC.getMicrophones()
      this.voiceList = await TRTC.getSpeakers()
      // debugger
      console.log(this.cameraList)
      //   alert(JSON.stringify(this.cameraList))
      this.voiceList = this.voiceList.filter(spk => {
        return spk.deviceId.length > 0
      })

      if (this.cameraList.length > 0) {
        this.hasCameraDevice = true
      }
      if (this.micList.length > 0) {
        this.hasMicDevice = true
      }
      this.cameraList.forEach(camera => {
        if (camera.deviceId.length > 0) {
          this.hasCameraConnect = true
        }
      })
      this.micList.forEach(mic => {
        if (mic.deviceId.length > 0) {
          this.hasMicConnect = true
        }
      })
      //   this.voiceList.forEach(voice => {
      //     if (voice.deviceId.length > 0) {
      //       this.hasVoiceConnect = true
      //     }
      //   })
      this.hasVoiceConnect = true
      this.hasNetworkConnect = !!navigator.onLine
    },
    // 播放器设备测试
    async startVoiceTesting() {
      await this.updateVoiceDeviceList()
      await this.createLocalStream(
        {
          audio: true,
          video: false,
          cameraId: this.cameraTestingResult.device.deviceId
        },
        'camera-check-video'
      )
    },
    async updateVoiceDeviceList() {
      // 获取扬声器设备并展示在界面中
      let voiceDevices = this.voiceList
      // 如果有用户设备选择缓存，优先使用缓存的deviceId
      let cacheVoiceDevice = voiceDevices.filter(
        mic => mic.deviceId === localStorage.getItem('txy_webRTC_voiceId')
      )
      if (cacheVoiceDevice.length > 0) {
        this.voiceTestingResult.device = cacheVoiceDevice[0]
      } else if (voiceDevices.length > 0) {
        this.voiceTestingResult.device = voiceDevices[0]
      }
      this.voiceId = this.voiceTestingResult.device?.deviceId
      if (this.voiceId && voiceDevices.length > 1) {
        localStorage.setItem('txy_webRTC_voiceId', this.voiceId)
        try {
          let audioPlayer = document.querySelector('#deviceCheckAudio')
          await audioPlayer.setSinkId(this.voiceId)
        } catch (error) {
          console.log(error)
        }
      }
    },

    // 弹窗-设备连接检查
    startDeviceConnect() {
      console.log(this.hasCameraDevice)
      console.log(this.hasMicDevice)
      // 如果有设备未连接，唤起请求弹窗
      if (
        !(this.hasCameraConnect && this.hasVoiceConnect && this.hasMicConnect)
      ) {
        // console.log(navigator.mediaDevices)
        navigator.mediaDevices
          .getUserMedia({
            video: true,
            audio: true
          })
          .then(async () => {
            // 重新获取设备信息
            await this.getDevicesInfo()
          })
          .catch(err => {
            console.log('err', err.name)
          })
      }
    },
    async startCameraTesting() {
      await this.updateCameraDeviceList()
      await this.createLocalStream(
        {
          audio: false,
          video: true,
          cameraId: this.cameraTestingResult.device.deviceId
        },
        'camera-check-video'
      )
      this.carameIsOpen = true
    },
    // 摄像头检测页-检测展示摄像头设备选择列表
    async updateCameraDeviceList() {
      let cameraDevices = this.cameraList
      console.log(this.cameraList)

      // 如果有用户设备选择缓存，优先使用缓存的deviceId
      let cacheCameraDevice = cameraDevices.filter(
        camera =>
          camera.deviceId === localStorage.getItem('txy_webRTC_cameraId')
      )
      if (cacheCameraDevice.length > 0) {
        this.cameraTestingResult.device = cacheCameraDevice[0]
      } else {
        this.cameraTestingResult.device = cameraDevices[0]
      }
      this.cameraId = this.cameraTestingResult.device?.deviceId
      if (this.cameraId) {
        localStorage.setItem('txy_webRTC_cameraId', this.cameraId)
      }
    },

    async updateMicDeviceList() {
      // 展示麦克风设备选择
      let micDevices = this.micList
      //micDevices = micDevices.filter(mic => mic.deviceId !== 'default')

      // 如果有用户设备选择缓存，优先使用缓存的deviceId
      let cacheMicDevice = micDevices.filter(
        mic => mic.deviceId === localStorage.getItem('txy_webRTC_micId')
      )
      if (cacheMicDevice.length > 0) {
        this.micTestingResult.device = cacheMicDevice[0]
      } else {
        this.micTestingResult.device = micDevices[0]
      }
      this.micId = this.micTestingResult.device?.deviceId
      if (this.micId) {
        this.localStream.switchDevice('audio', this.micId)
        localStorage.setItem('txy_webRTC_micId', this.micId)
      }
    },

    // 麦克风设备测试
    async startMicTesting() {
      let audioPlayer = document.querySelector('#deviceCheckAudio')
      if (audioPlayer) {
        audioPlayer.pause()
      }
      await this.updateMicDeviceList()
      await this.createLocalStream(
        {
          audio: true,
          video: false,
          cameraId: this.cameraTestingResult.device.deviceId
        },
        'camera-check-video'
      )

      // 监听音量，并量化显示出来
      this.myVolumeTimer = setInterval(() => {
        let volume = this.localStream.getAudioLevel()
        let num = Math.ceil(28 * volume)
        this.myVolume = (num / 100) * 4
      }, 100)
    },

    async startNetworkTesting() {
      this.logList.push(`startNetworkTesting`)
      // 获取信息
      let userInfo = {}
      let liverInfo = {}
      let appInfo = {}
      const testInfo = await authApi.devicetest().catch(err => {
        console.error(err)
        this.networdCheckError = true
        return false
      })
      this.roomId = String(testInfo.data.roomNum)
      console.log(testInfo)
      appInfo = {
        sdkAppId: testInfo.data.appId,
        roomId: testInfo.data.roomNum
      }
      userInfo = {
        userId: testInfo.data.downLinkUser.userId,
        userSig: testInfo.data.downLinkUser.userSig
      }
      liverInfo = {
        userId: testInfo.data.upLinkUser.userId,
        userSig: testInfo.data.upLinkUser.userSig
      }
      let uplinkClient = null // 用于检测上行网络质量
      let downlinkClient = null // 用于检测下行网络质量
      let testResult = {
        // 记录上行网络质量数据
        uplinkNetworkQualities: [],
        // 记录下行网络质量数据
        downlinkNetworkQualities: [],
        average: {
          uplinkNetworkQuality: 0,
          downlinkNetworkQuality: 0
        }
      }

      // 1. 检测上行网络质量
      const testUplinkNetworkQuality = async () => {
        uplinkClient = TRTC.createClient({
          sdkAppId: appInfo.sdkAppId, // 填写 sdkAppId
          userId: userInfo.userId,
          userSig: userInfo.userSig, // uplink_test 的 userSig
          mode: 'rtc',
          autoSubscribe: false,
          enableAutoPlayDialog: false
        })

        // localStream = TRTC.createStream({ audio: true, video: true })
        // // 根据实际业务场景设置 video profile
        // localStream.setVideoProfile('480p')
        // await localStream.initialize()

        uplinkClient.on('network-quality', event => {
          const { uplinkNetworkQuality, uplinkRTT } = event
          if (uplinkNetworkQuality > 0) {
            testResult.uplinkNetworkQualities.push(uplinkNetworkQuality)
          }

          this.logList.push(`uplinkNetworkQuality ${uplinkNetworkQuality}`)
          this.logList.push(`uplinkRTT ${uplinkRTT}`)
          this.networkUpLog.push(event)
        })

        // 加入用于测试的房间，房间号需要随机，避免冲突
        await uplinkClient.join({ roomId: appInfo.roomId })
        await this.createLocalStream(
          {
            audio: true,
            video: true,
            cameraId: this.cameraTestingResult.device.deviceId
          },
          'camera-check-video'
        )
        await uplinkClient.publish(this.localStream).catch(err => {
          console.error(err)
          this.networdCheckError = true
          return false
        })
      }
      if (this.countTimer) {
        clearInterval(this.countTimer)
      }
      this.countTimer = setInterval(() => {
        this.countDown++
        console.log(testResult.uplinkNetworkQualities)
        if (
          this.countDown >= 30 ||
          (testResult.uplinkNetworkQualities.length >= 6 &&
            testResult.downlinkNetworkQualities.length >= 6)
        ) {
          clearInterval(this.countTimer)
          sumNetQuality()
        }
      }, 1000)
      // 2. 检测下行网络质量
      const testDownlinkNetworkQuality = async () => {
        downlinkClient = TRTC.createClient({
          sdkAppId: appInfo.sdkAppId, // 填写 sdkAppId
          userId: liverInfo.userId,
          userSig: liverInfo.userSig, // userSig
          mode: 'rtc',
          autoSubscribe: false,
          enableAutoPlayDialog: false
        })

        downlinkClient.on('stream-added', async event => {
          await downlinkClient.subscribe(event.stream, {
            audio: true,
            video: true
          })
          // 订阅成功后开始监听网络质量事件
          downlinkClient.on('network-quality', event => {
            const { downlinkNetworkQuality, downlinkRTT } = event
            if (downlinkNetworkQuality > 0) {
              testResult.downlinkNetworkQualities.push(downlinkNetworkQuality)
            }

            this.logList.push(
              `downlinkNetworkQuality ${downlinkNetworkQuality}`
            )
            this.logList.push(`downlinkRTT ${downlinkRTT}`)
            this.networkDownLog.push(event)
          })
        })
        // 加入用于测试的房间，房间号需要随机，避免冲突
        await downlinkClient.join({ roomId: appInfo.roomId })
      }

      // 3. 开始检测
      testUplinkNetworkQuality()
      testDownlinkNetworkQuality()

      // 4. 15s 后停止检测，计算平均网络质量
      const sumNetQuality = () => {
        // 计算上行平均网络质量
        if (testResult.uplinkNetworkQualities.length >= 4) {
          testResult.average.uplinkNetworkQuality = Math.ceil(
            testResult.uplinkNetworkQualities.reduce(
              (value, current) => value + current,
              0
            ) / testResult.uplinkNetworkQualities.length
          )
          this.checkList.network.up = testResult.average.uplinkNetworkQuality
        } else {
          this.checkList.network.up = 6
        }

        if (testResult.downlinkNetworkQualities.length >= 4) {
          // 计算下行平均网络质量
          testResult.average.downlinkNetworkQuality = Math.ceil(
            testResult.downlinkNetworkQualities.reduce(
              (value, current) => value + current,
              0
            ) / testResult.downlinkNetworkQualities.length
          )
          this.checkList.network.down =
            testResult.average.downlinkNetworkQuality
        } else {
          this.checkList.network.down = 6
        }
        // 如果有设备无法初始化，强制网络状态为7
        if (
          this.checkList.network.down === 0 ||
          this.checkList.network.up === 0
        ) {
          this.networdCheckError = true
        }
        // 检测结束，清理相关状态。
        this.logList.push(
          `network test end data is ${JSON.stringify(testResult, null, '\t')}`
        )
        uplinkClient.leave()
        downlinkClient.leave()
        this.localStream.close()
        this.sendTestData()
      }
    },

    /**
     *createStream共通処理メソッド
     */
    async createLocalStream(constraints, container) {
      this.localStream = markRaw(TRTC.createStream(constraints))
      console.log('..............track:', this.localStream.getVideoTrack())
      // navigator.mediaDevices
      //   .getUserMedia({ video: true, audio: true })
      //   .then(async () => {
      //     console.log('..........getUserMedia:', 'ok')
      //   })
      //   .catch(error => {
      //     console.log('..........getUserMedia error:', error)
      //   })
      try {
        await this.localStream.initialize()
        console.log('createLocalStream!')
      } catch (error) {
        console.log(error)
      }
      container && this.localStream.play(container)
    },
    sendTestData() {
      const params = {
        roomId: this.roomId,
        rococoUserId: String(this.userid) || '',
        cameraId: this.cameraTestingResult.device?.deviceId || '',
        cameraName: this.cameraTestingResult.device?.label || '',
        speakerId: this.voiceTestingResult.device?.deviceId || '',
        speakerName: this.voiceTestingResult.device?.label || '',
        micId: this.micTestingResult.device?.deviceId || '',
        micName: this.micTestingResult.device?.label || '',
        uplinkQualityAll: JSON.stringify(this.networkUpLog),
        uplinkQuality: String(this.checkList.network.up),
        downlinkQualityAll: JSON.stringify(this.networkDownLog),
        downlinkQuality: String(this.checkList.network.down),
        userAgent: navigator.userAgent
      }
      authApi.devicetestSave(params)
      console.log(params)
    }
  }
})
</script>

<style scoped>
.device-check-content {
  --background: #ffffff;
}
.device-check-stp1-title {
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #4b4a4b;
  padding-top: 10vh;
}
.device-check-stp1-sub-title {
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #8a8a8a;
  padding-top: 20px;
}
.device-check-stp1-tips {
  padding: 40px 0;
  min-height: 50px;
  font-size: 16px;
  text-align: center;
}
/* .device-check-stp1-tips.is-ok {
  text-align: left;
} */
.device-check-stp1-btn-body {
  text-align: center;
}
.device-check-stp1-btn {
  width: 232px;
  height: 41px;
  background: #e05193;
  border-radius: 4px;
  color: #ffffff;
}
.device-check-stp1-btn:disabled {
  background-color: #e1dddd !important;
}
.device-check-stp1-mid-body {
  margin: 20px;
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 20px;
  min-height: 95px;
}
.device-check-stp1-icon-item {
  float: left;
  width: 25%;
  text-align: center;
  font-size: 24px;
}
.device-check-checked-icon {
  line-height: 16px;
}
.device-check-checked-ok {
  color: #7bd064;
  font-size: 16px;
}
.device-check-checked-error {
  color: #e11616;
  font-size: 16px;
}
.device-check-stp1-progress-body {
  margin-top: 10px;
}
.cRed {
  color: #e11616;
}
.cGreen {
  color: #7bd064;
}
.cOrange {
  color: #de7800;
}
.cWhite {
  color: #ffffff;
}
.margin-auto {
  margin: 0 auto;
}
.device-check-radio-body {
  background-color: #f7f7f7;
  padding: 20px;
}
.device-check-radio-label {
  padding-left: 10px;
}
.device-check-radio-item {
  margin: 5px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 26px;
  line-height: 26px;
}
.device-check-radio-item input {
  vertical-align: -2px;
}
.device-check-stp2-video-body {
  margin-bottom: 40px;
  min-height: 170px;
  max-height: 500px;
  height: calc(100vh - 500px);
}
.device-check-radio-title {
  text-align: center;
  font-size: 14px;
  margin-bottom: 16px;
}
.device-check-common-tips {
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: rgba(0, 0, 0, 0.54);
  padding: 15px 0;
}
.device-check-common-bottom-btn-body {
  text-align: center;
  padding-bottom: 30px;
}
.device-check-common-bottom-btn {
  width: 100px;
  height: 40px;
  border-radius: 4px;

  font-size: 14px;
  line-height: 16px;
  text-align: center;
}
.device-check-common-bottom-btn.type-left {
  margin-right: 10%;
  /* いいえ */

  color: #e05193;
  border: 1px solid #e05193;
  background-color: #ffffff;
}
.device-check-common-bottom-btn.type-right {
  margin-left: 10%;
  background: #e05193;
  color: #ffffff;
}
.device-check-stp3-audio-body {
  padding: 30px 0;
  text-align: center;
}
.device-check-stp4-progress {
  height: 30px;
  --background: #efefef;
  --progress-background: #e05193;
}
.device-check-stp4-tips {
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.54);
  padding: 20px 0;
}
.device-check-stp4-progress-item {
  height: 30px;
  width: 2%;
  border: 1px solid #8a8a8a;
  float: left;
}
.device-check-stp4-progress-item:nth-child(odd) {
  border: 0px solid #8a8a8a;
  background-color: #ffffff;
}
.device-check-stp4-progress-body {
  position: relative;
  margin-bottom: 60px;
}
.device-check-stp4-progress-mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 30px;
  z-index: 2;
}
.device-check-stp5-title {
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #4b4a4b;
  padding: 5vh 0;
}
.device-check-stp5-tips {
  padding: 42px 0;
  font-size: 16px;
  line-height: 24px;

  /* grey/b54 */
  color: rgba(0, 0, 0, 0.54);
  max-width: 375px;
  margin: 0 auto;
}
.device-check-stp5-cir {
  width: 106px;
  height: 106px;
  text-align: center;
  line-height: 106px;
  margin: 0 auto;
  border-radius: 106px;
  margin-bottom: 30px;
  font-size: 72px;
  /* color: #ffffff; */
}
.device-check-stp5-ten {
  font-size: 28px;
  font-weight: bold;
}
.device-check-stp5-cir.green {
  background-color: #21a926;
}
.device-check-stp5-cir.orange {
  background-color: #de7800;
}
.device-check-stp5-cir.red {
  background-color: #e11616;
}
.device-check-stp6-title {
  /* テスト結果 */
  font-size: 24px;
  text-align: center;

  /* grey/80 */
  color: #4b4a4b;
  padding: 10vh 0;
}
.device-check-stp6-table {
  width: 100%;
  margin-bottom: 20px;
}
.device-check-stp6-table td {
  vertical-align: middle;
  padding: 15px;
  color: #4b4a4b;
}
.device-check-stp6-table ion-icon {
  vertical-align: middle;
}
.device-check-stp6-table td.device-check-stp6-table-icon {
  font-size: 24px;
  vertical-align: middle;
  text-align: center;
  background: #f7f7f7;
}
.device-check-top-nav {
  background: #f7f7f7;
  padding: 10px;
  margin: 20px 0;
}
.device-check-top-nav-icon {
  font-size: 30px;
}
.device-check-top-nav-bar {
  height: 2px;
  width: 100%;
  margin: 0 5px;
  background: #bfbfbf;
}
.device-check-top-nav-icon.active {
  color: #2e6cf6;
}
.device-check-top-nav-bar.active {
  background: #2e6cf6;
}
.device-check-stp7-title {
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #e11616;
  margin-bottom: 15px;
  margin-top: 6vh;
}
.device-check-stp7-res {
  font-size: 16px;
  color: #8a8a8a;
  max-width: 390px;
  margin: 0 auto 18px auto;
}
.device-check-stp7-tips {
  font-size: 12px;
  line-height: 21px;
  color: #8a8a8a;
  padding-bottom: 20px;
}
.device-check-stp7-circle {
  padding-right: 9px;
}
.device-check-stp7-dot {
  padding-left: 7px;
  padding-right: 10px;
}
.common-authing-body.devicecheck {
  width: 145px;
  padding: 15px 15px 15px 15px;
  height: 114px;
}
</style>
<style>
.device-check-stp2-video-body video {
  vertical-align: middle;
  font-size: 16px;
  object-fit: contain !important;
}
</style>
